import morphdom from "morphdom";
import Mousetrap from "mousetrap";
import { sortBy } from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "input", "dropdown", "option", "noOptions"];
  debug = true;

  connect() {
    this.render();
    this.removeDuplicateOptions();
  }

  removeDuplicateOptions() {
    // remove item from options if it's passed as selected
    const selectedIds = this.getState("selected").map(item => item.id);
    const newOptions = this.getState("options").filter(item => !selectedIds.includes(item.id));
    this.setState("options", newOptions);
  }

  render(morph = true) {
    if (this.debug) console.log("rendered");
    const name = this.element.dataset.name;
    const selected = this.getState("selected");
    const options = this.getState("options");
    const filteredOptions = this.getFilteredOptions(options);
    const sortedOptions =
      this.orderValue == "alphabetical" ? sortBy(filteredOptions, "name") : filteredOptions.reverse();
    const placeholder = this.getState("placeholder");
    const cursor = parseInt(this.getState("cursor"));
    const showDropdown = this.getState("showDropdown") == "true";
    if (showDropdown) {
      $(this.element).addClass("active");
    } else {
      $(this.element).removeClass("active");
    }

    let content = `
      <div>
        <div class="inner">
          ${selected
            .map(item => {
              return `
            <input type="hidden" name="${name}[]" value="${item.id}" />
            <div class='selected'>
                <div class='name'>${item.name}</div>
                ${
                  item.more_information && item.more_information != "null"
                    ? `<div class="extra-information"><i class="fal fa-location"></i><span>${item.more_information}</span></div>`
                    : ``
                }
                <div class='close' data-action='click->multi-select#removeSelected'
                  data-id='${item.id}' data-name='${item.name}' data-more_infomation='${item.more_information}'
                >
                  <i class="fal fa-times"></i>
                </div>
              </div>
            `;
            })
            .join("")}

          <!-- spacer -->
          ${selected.length === 0 ? `<div style="width: 8px"></div>` : ""}

          <input
            type="text"
            class="mousetrap"
            data-multi-select-target="input"
            data-action="
              input->multi-select#search
              focus->multi-select#showDropdown
            "
            ${placeholder && selected.length === 0 ? `placeholder="${placeholder}"` : ""}
            value="${this.getState("search")}"
          />

          <div
            class="dropdown"
            data-multi-select-target="dropdown"
            style="${showDropdown ? "" : "display: none"}; z-index: 2;"
          >
            ${sortedOptions
              .map((item, index) => {
                return `
                <li
                  class='option ${index === cursor ? `active` : ""}'
                  data-multi-select-target='option'
                  data-id=${typeof item.id === "string" ? JSON.stringify(item.id) : item.id}
                  data-name=${JSON.stringify(item.name)}
                  data-more_infomation='${item.more_information}'
                  data-action='click->multi-select#selectOption' xmlns="http://www.w3.org/1999/html">

                  <div class="name">${item.name}</div>
                  ${
                    item.more_information && item.more_information != "null"
                      ? `<div class="extra-information"><i class="fal fa-location"></i><span>${item.more_information}</span></div>`
                      : ``
                  }
                </li>`;
              })
              .join("")}

            ${
              sortedOptions.length === 0
                ? `<div class="no-options" data-multi-select-target="noOptions">No options</div>`
                : ""
            }
          </div>
        </div>
      </div>
      `;

    if (morph) {
      morphdom(this.element, content, { childrenOnly: true });
    } else {
      this.element.innerHTML = content;
    }

    const newHeight = $(this.element).height();
    $(this.dropdownTarget).css("top", newHeight + 4 + "px");
  }

  getFilteredOptions(options) {
    const search = this.getState("search");
    if (!search) return options;
    const filteredOptions = options.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    return filteredOptions;
  }

  search(e) {
    this.setState("search", this.inputTarget.value);
    this.setState("cursor", "0");
    this.render();
  }

  dispatchFormInput() {
    const form = this.inputTarget.form;
    if (form) {
      const event = new Event("input", { bubbles: true });
      form.dispatchEvent(event);
    }
  }

  onBodyClick(e) {
    if (this.debug) console.log("onBodyClick");
    if (this.element.contains(e.target)) {
      return;
    }
    if (this.getState("showDropdown") == "true") this.hideDropdown();
  }

  onTab() {
    this.hideDropdown();
  }

  onEscape() {
    this.inputTarget.blur();
    this.hideDropdown();
  }

  showDropdown() {
    if (this.debug) console.log("show dropdown");
    this.setState("showDropdown", "true");
    this.setState("cursor", "0"); // reset the cursor on open
    this.render();
    Mousetrap.bind("enter", this.selectOptionUnderCursor.bind(this));
    Mousetrap.bind("down", this.moveCursorDown.bind(this));
    Mousetrap.bind("up", this.moveCursorUp.bind(this));
    Mousetrap.bind("esc", this.onEscape.bind(this));
    Mousetrap.bind("backspace", this.onBackspace.bind(this));
    Mousetrap.bind("tab", this.onTab.bind(this));
    Mousetrap.bind("shift+tab", this.onTab.bind(this));
  }

  hideDropdown() {
    if (this.debug) console.log("hide dropdown");
    this.setState("showDropdown", "false");
    this.render();
    Mousetrap.unbind("enter");
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("tab");
    Mousetrap.unbind("tab");
    Mousetrap.unbind("shift+tab");
    Mousetrap.unbind("backspace");
  }

  moveCursorDown() {
    // todo: disallow out of bounds cursor
    if (this.debug) console.log("move down");
    let newCursor = parseInt(this.getState("cursor")) + 1;

    const optionsLength = this.getState("options").length - 1;
    if (newCursor > optionsLength) newCursor = 0;

    this.setState("cursor", newCursor);
    this.render();
    this.scrollParentToChild(this.getCurrentCursorElement());
  }

  moveCursorUp() {
    // todo: disallow out of bounds cursor
    if (this.debug) console.log("move up");
    let newCursor = parseInt(this.getState("cursor")) - 1;

    if (newCursor < 0) newCursor = this.getState("options").length - 1;

    if (this.debug) console.log("new cursor", newCursor);
    this.setState("cursor", newCursor);
    this.render();
    this.scrollParentToChild(this.getCurrentCursorElement());
  }

  scrollIntoViewIfNeeded(target) {
    if (target.getBoundingClientRect().bottom > target.parentElement.innerHeight) {
      target.scrollIntoView(false);
    }

    if (target.getBoundingClientRect().top < 0) {
      target.scrollIntoView();
    }
  }

  scrollParentToChild(child) {
    if (!child) return;
    const parent = child.parentElement;
    if (this.debug) console.log(parent);
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };
    // Where is the child
    const childRect = child.getBoundingClientRect();
    // Is the child viewable?
    const isViewable = childRect.top >= parentRect.top && childRect.top <= parentRect.top + parentViewableArea.height;
    // if you can't see the child try to scroll parent
    if (!isViewable) {
      if (this.debug) console.log("not viewable");
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
    }
  }

  getState(name) {
    // to improve performance, instead of always having to parse then stringify, this state could be
    // stored in JS memory instead of HTML; it could be synced back to HTML when you render
    const value = this.data.get(name);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  setState(name, data) {
    this.data.set(name, JSON.stringify(data));
  }

  selectOption(e) {
    e.preventDefault();
    e.stopPropagation();
    const el = e.currentTarget;
    this.selectOptionFromElement(el);
    this.dispatchFormInput();
  }

  getCurrentCursorElement() {
    return $(this.dropdownTarget).children(".active")[0];
  }

  selectOptionUnderCursor() {
    const el = this.getCurrentCursorElement();
    if (!el) return;
    this.selectOptionFromElement(el);
  }

  removeSelected(e) {
    e.preventDefault();
    e.stopPropagation();

    const element = e.currentTarget;

    const name = element.dataset.name;
    const id = element.dataset.id;
    const moreInformation = element.dataset.more_information;
    this.removeSelectedByValues(name, id, moreInformation);
    this.dispatchFormInput();
  }

  onBackspace() {
    // todo: check if cursor is out of bounds, then move it if yes.
    const last = this.getState("selected").slice(-1)[0];
    if (!this.getState("search") && last) {
      this.removeSelectedByValues(last.name, last.id, last.more_information);
    }
  }

  renderWithCleared() {
    this.setState("search", "");
    this.render();
  }

  renderWithClearedAndFocusedInput() {
    this.setState("search", "");
    this.render();
    this.inputTarget.focus();
  }

  selectOptionFromElement(el) {
    const name = el.dataset.name;
    const id = el.dataset.id;
    const more_information = el.dataset.more_infomation;

    let newSelected = this.getState("selected").concat({ name, id, more_information });
    this.setState("selected", newSelected);
    let newOptions = this.getState("options").filter(items => String(items.id) !== String(id));
    this.setState("options", newOptions);
    this.renderWithClearedAndFocusedInput();
    this.callOnChange();
  }

  reset() {
    let newSelected = this.getState("selected");
    this.setState("selected", []);
    let newOptions = this.getState("options");
    this.setState(
      "options",
      newSelected.concat(newOptions).sort((a, b) => b.name.localeCompare(a.name)),
    );
    this.renderWithCleared();
    this.callOnChange();
  }

  removeSelectedByValues(name, id, more_information) {
    let newSelected = this.getState("selected").filter(items => String(items.id) !== String(id));
    this.setState("selected", newSelected);
    let newOptions = this.getState("options").concat({ name: name, id: id, more_information: more_information || "" });
    this.setState("options", newOptions);
    this.renderWithClearedAndFocusedInput();
    this.callOnChange();
  }

  callOnChange() {
    const data = this.element.dataset;
    let onChangeElement = data.onChangeElement;
    let setInputElement = data.setInputElement;
    if (!onChangeElement && !setInputElement) return;
    if (setInputElement) {
      const inputElement = document.querySelector(setInputElement);
      inputElement.value = this.getState("selected").map(s => s.id);
    }
    if (!onChangeElement) return;
    onChangeElement = $(onChangeElement)[0];
    const onChangeCall = data.onChangeCall.split("#");
    const onChangeControllerName = onChangeCall[0];
    const onChangeFunction = onChangeCall[1];
    const controller = this.application.getControllerForElementAndIdentifier(onChangeElement, onChangeControllerName);
    controller[onChangeFunction].call(controller, this.getState("selected"));
  }
}

// add x on far right
// add caret on far right
// when tabbing over to another select, hide the dropdown
// set no options block to width of element
