import ApplicationController from "../../../plus/src/controllers/application_controller";

export class TurboSubmitLoadingController extends ApplicationController {
  connect() {
    this.formElement = this.element.form
    if (!this.formElement) {
      throw new Error("Controller must be attached to a submit button within a form element")
    }

    this.formElement.addEventListener("turbo:submit-start", this.addLoadingClass)
    this.formElement.addEventListener("turbo:submit-end", this.removeLoadingClass)
  }

  disconnect() {
    this.formElement.removeEventListener("turbo:submit-start", this.addLoadingClass)
    this.formElement.removeEventListener("turbo:submit-end", this.removeLoadingClass)
  }

  addLoadingClass = () => {
    this.element.classList.add('loading-shine')
  }

  removeLoadingClass = () => {
    this.element.classList.remove('loading-shine')
  }
}
